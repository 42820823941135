<template>
  <svg :height="`${height}px`" :width="`${width}px`" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 3.66667C0 2.69421 0.381919 1.76158 1.06174 1.07394C1.74156 0.386308 2.66359 0 3.625 0H25.375C26.3364 0 27.2584 0.386308 27.9383 1.07394C28.6181 1.76158 29 2.69421 29 3.66667V12.8333H0V3.66667ZM20.8438 5.5C20.6034 5.5 20.3729 5.59658 20.2029 5.76849C20.033 5.94039 19.9375 6.17355 19.9375 6.41667V8.25C19.9375 8.49312 20.033 8.72627 20.2029 8.89818C20.3729 9.07009 20.6034 9.16667 20.8438 9.16667H24.4688C24.7091 9.16667 24.9396 9.07009 25.1096 8.89818C25.2795 8.72627 25.375 8.49312 25.375 8.25V6.41667C25.375 6.17355 25.2795 5.94039 25.1096 5.76849C24.9396 5.59658 24.7091 5.5 24.4688 5.5H20.8438ZM0 16.5V18.3333C0 19.3058 0.381919 20.2384 1.06174 20.9261C1.74156 21.6137 2.66359 22 3.625 22H25.375C26.3364 22 27.2584 21.6137 27.9383 20.9261C28.6181 20.2384 29 19.3058 29 18.3333V16.5H0Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#F7931E'
    },
    width: {
      type: String,
      default: '29'
    },
    height: {
      type: String,
      default: '22'
    }
  }
}
</script>
